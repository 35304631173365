<template>
  <div class="exchange-page">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item> 人才交流 </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="exchange-list">
      <h4>人才交流</h4>
      <ul class="list">
        <li
          class="list-item"
          v-for="talent in talentList.list"
          :key="talent.id"
        >
          <div class="item-info">
            <div class="info-title">
              <span class="title-pos">{{ talent.job_name }}</span>
              <span class="title-time">{{ talent.times }} 发布</span>
            </div>
            <div class="info-msg">
              <div class="price-require">
                <span class="price"
                  >{{ talent.salary_low }}-{{ talent.salary_hign }}</span
                >
                <span class="require"
                  >{{ talent.province }}{{ talent.county }} |
                  {{ talent.education }} | {{ talent.experience }}</span
                >
              </div>
            </div>
          </div>
          <div class="item-intro">
            <div class="intro-company">
              <span>{{ talent.company_name }}</span>
            </div>
            <div class="intro-msg">
              <span
                >{{ talent.company_type }}
                <!-- {{
                  talent.financing == 1
                    ? "天使轮"
                    : talent.financing == 2
                    ? "A轮"
                    : talent.financing == 3
                    ? "B轮"
                    : talent.financing == 4
                    ? "C轮"
                    : talent.financing == 5
                    ? "D轮及以上"
                    : "已上市"
                }} -->
                | {{ talent.scale }}人</span
              >
            </div>
          </div>
          <div class="item-button">
            <button @click="$router.push(`/exdetail?exid=${talent.id}`)">
              查看职位
            </button>
          </div>
        </li>
      </ul>
      <el-pagination
        :current-page="currentPage"
        layout="prev,pager,next"
        :total="talentList.count"
        :page-size="10"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="pageChange"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      talentList: {},
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.getTalentList();
  },
  methods: {
    async getTalentList(page = 1) {
      let { data } = await this.axios.get(`/index/recruit_list?page=${page}`);
      this.talentList = data;
    },
    pageChange(page) {
      this.getTalentList(page);
    },
  },
};
</script>
<style lang="scss" scoped>
.exchange-page {
  padding-bottom: 372px;
  .exchange-list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    h4 {
      display: inline-block;
      font-size: 18px;
      color: #20a5f0;
      font-weight: normal;
      margin: 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #20a5f0;
        position: absolute;
        bottom: -5px;
        left: 0;
      }
    }
    .list {
      $grey: #666;
      $orange: #fe8720;
      list-style: none;
      margin: 0;
      margin-top: 50px;
      padding: 0;

      .list-item {
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
        background-color: #fff;
        border-radius: 3px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba($color: #d0d0d0, $alpha: 0.34);
        user-select: none;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
        }
        .item-info {
          flex: 2;

          .info-title {
            .title-pos {
              font-size: 20px;
            }
            .title-time {
              font-size: 14px;
              color: #999;
              margin-left: 20px;
            }
          }
          .info-msg {
            margin-top: 20px;
            .price-require {
              .price {
                color: $orange;
                font-size: 18px;
              }
              .require {
                font-size: 14px;
                color: $grey;
                margin-left: 35px;
              }
            }
          }
        }
        .item-intro {
          flex: 1;
          .intro-company {
            font-size: 16px;
          }
          .intro-msg {
            color: $grey;
            margin-top: 20px;
          }
        }
        .item-button {
          flex: 1;
          text-align: right;
          button {
            width: 100px;
            height: 30px;
            border-radius: 15px;
            color: #fff;
            background-color: $orange;
            border: 0;
            outline: 0;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
    .el-pagination {
      text-align: center;
      margin-top: 36px;
    }
  }
}
</style>
<style lang="scss">
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
